<template>
  <div class="share-info" @scroll="handleScroll">
    <div class="share-total">{{ titleText }} {{ totalCount }}人</div>
    <ul class="share-list">
      <li v-for="(item, index) in list" :key="index" class="share-item">
        <div class="item-avatar">
          <img :src="item.headImgUrl" alt="" />
        </div>
        <div class="item-text">
          <div class="up">
            <span class="nickName">{{ item.nickName }}</span>
            <span class="companyName">{{ item.lawfirmName }}</span>
          </div>
          <div class="down">
            <span class="get-money">
              获得佣金
              <span class="money">￥{{ item.brokerage }}</span>
            </span>
            <span class="reg-time">
              {{ item.registerTime }}
              <span>注册</span>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'share-info',
  props: ['mode'],
  data() {
    return {
      routeName: '',
      list: [],
      totalCount: 0,
      pageNum: 0,
      hasMore: true,
      loading: false,
    }
  },
  computed: {
    titleText() {
      if (this.routeName === 'mybuy') {
        return '已购买'
      } else if (this.routeName === 'myRegister') {
        return '邀请注册'
      } else {
        return ''
      }
    },
  },
  methods: {
    async getList() {
      this.loading = true
      let url = this.$fissionBase
      if (this.routeName === 'mybuy') {
        url += '/fission/statistics/purchasedList.do'
      } else if (this.routeName === 'myRegister') {
        url += '/fission/statistics/registeredList.do'
      } else {
        return
      }
      try {
        const params = { type: 0, pageNum: this.pageNum, pageSize: 10 }
        setTimeout(async () => {
          const { data } = await this.$axios.post(url, params)
          this.loading = true
          if (data.code === '200') {
            if (this.routeName === 'mybuy') {
              this.list = [...this.list, ...data.data.purchasedList]
            } else if (this.routeName === 'myRegister') {
              this.list = [...this.list, ...data.data.registeredList]
            }
            this.totalCount = data.data.totalCount
            this.hasMore = data.data.totalPage > this.pageNum + 1
          }
        }, 1000)
      } catch (err) {
        this.loading = true
        console.log(err)
      }
    },
    handleScroll(e) {
      if ((e.target.scrollTop + e.target.offsetHeight).toFixed() >= e.target.scrollHeight) {
        if (this.hasMore) {
          this.pageNum++
          this.getList()
        } else {
          this.$notify('没有更多内容')
        }
      }
    },
  },
  created() {
    this.routeName = this.$route.name
    this.getList()
  },
}
</script>

<style lang="stylus" scoped>
.share-info
  width 100%
  max-height 95vh
  box-sizing border-box
  font-size 14px
  overflow-y auto
  .share-total
    line-height 40px
    font-size 16px
    padding 0 10px
  .share-list
    .share-item
      display flex
      .item-avatar
        width 80px
        height 80px
        line-height 80px
        display flex
        justify-content center
        align-items center
        img
          width 50px
          height 50px
          border-radius 50%
      .item-text
        flex 1
        display flex
        padding 10px 0
        line-height 30px
        flex-direction column
        .up
          display flex
          .nickName
            width 70px
            font-size 16px
          .companyName
            max-width calc(100vw - 160px)
            white-space nowrap
            overflow hidden
            text-overflow ellipsis
        .down
          display flex
          color #555
          .get-money
            width 130px
            .money
              font-size 16px
              color red
              font-weight bold
</style>
